html,
body {
  margin: 0;
  padding: 0;
}

body {
  margin: 0 auto;
  padding: 1rem;
  max-width: 960px;
  font-size: 1rem;
}

header pre {
  font-size: 0.625rem;
}

main ul {
  padding-left: 20px;
}

footer {
  margin-top: 5rem;
}
